<template>
  <div id="MyAuth" class="wrap">
    <div class="formWrap">
      <h1 class="title">ログイン情報変更</h1>
      <div class="inputBox">

        <div class="input-wrap">
          <b-container fluid>
            <b-row class="nowMail">
              <b-col sm="6" class="labelWrap">
                <label>大学名</label>
              </b-col>
              <b-col sm="5" class="pl-30">
                <span class="dataText" v-if="user.university">
                  {{ user.university.university_name }}
                </span>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="input-wrap">
          <v-form-text
            itemStyle="line"
            label="部署等"
            maxLength="100"
            labelGridClass="inputLabel"
            placeholder="例）国際交流部"
            :errMessage="errors.busyo || []"
            v-model="form.busyo"
            :isRequired="true"
          >
          </v-form-text>
        </div>

        <div class="input-wrap">
          <v-form-text
            itemStyle="line"
            label="郵便番号"
            maxLength="7"
            labelGridClass="inputLabel"
            placeholder="例）5401234"
            :errMessage="errors.postal_code || []"
            v-model="form.postal_code"
            :isRequired="true"
          >
          </v-form-text>
        </div>

        <div class="input-wrap">
          <v-form-text
            itemStyle="line"
            label="所在地"
            maxLength="100"
            labelGridClass="inputLabel"
            placeholder="例）東京都○○区○○町1-2-3"
            :errMessage="errors.location || []"
            v-model="form.location"
            :isRequired="true"
          >
          </v-form-text>
        </div>

        <div class="input-wrap">
          <v-form-text
            itemStyle="line"
            label="電話番号"
            maxLength="100"
            labelGridClass="inputLabel"
            placeholder="例）0363283030"
            :errMessage="errors.tel || []"
            v-model="form.tel"
            :isRequired="true"
          ></v-form-text>
        </div>

        <div class="input-wrap">
          <b-container fluid>
            <b-row class="nowMail">
              <b-col sm="6" class="labelWrap">
                <label>現在のメールアドレス</label>
              </b-col>
              <b-col sm="5" class="pl-30">
                <span class="dataText">
                  {{ user.login_id }}
                </span>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="passBox">
          <div class="check-wrap mt-3 my-2">
            <b-form-checkbox
              v-model="changePassword"
              switches
              >パスワードを変更する</b-form-checkbox>
          </div>
          <div class="message">
            <span :class="changePassword?'guide':'gray guide'">
              英大文字・英小文字・数字・記号をそれぞれ1つ以上利用し8文字以上入力してください。
            </span>
          </div>
          <v-form-text
            label="新しいパスワード"
            type="password"
            itemStyle="line"
            :errMessage="errors.newPassword || []"
            @check = 'passCheck'
            v-model="newPassword"
            maxLength="255"
            :isRequired="true"
            :disabled="!changePassword"
          ></v-form-text>
          <v-form-text
            label="新しいパスワードの確認"
            type="password"
            itemStyle="line"
            :errMessage="errors.newPasswordConf || []"
            @check = 'passCheck'
            v-model="newPasswordConf"
            maxLength="255"
            :isRequired="true"
            :disabled="!changePassword"
          ></v-form-text>
        </div>
      </div>
    </div>
    <div class="formWrap">
      <div class="">
        <div class="inputWrap">

        </div>
      </div>
      <div class="btnWrap">
        <b-button
          class="btn save bold"
          @click="save"
        >
          保存
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import { Core as YubinBangoCore } from 'yubinbango-core';
import { cloneDeep } from 'lodash';
import api from '@/modules/api';

export default {
  name: 'uniSettingView',
  components: {
  },
  data() {
    return {
      user: {},
      errors: {},
      newPassword: '',
      newPasswordConf: '',
      changePassword: false,
      form: {
        busyo: '',
        location: '',
        tel: '',
        postal_code: '',
      },
      isInitSeting: true,
    };
  },
  computed: {
    isSamePass() {
      return this.newPassword !== '' && this.newPassword === this.newPasswordConf;
    },
  },
  methods: {
    init() {
      this.user = {};
      this.errors = {};
      this.newPassword = '';
      this.newPasswordConf = '';
      this.changePassword = false;
      this.form.busyo = '';
      this.form.location = '';
      this.form.tel = '';
      this.form.postal_code = '';
      this.isInitSeting = true;
    },
    // 管理者情報
    async fetch() {
      this.$store.dispatch('page/onLoading');
      const response = await api.send('/api/uni/setting/edit')
        .catch(() => false);
      if (!response) {
        this.isInitSeting = false;
        this.$store.dispatch('page/offLoading');
        return;
      }
      this.isInitSeting = true;
      this.$store.commit('cmsSetting/setDbData', response.data.edit);
      this.user = response.data.user;
      if (response.data.user && response.data.user.university) {
        this.form.busyo = cloneDeep(response.data.user.university.busyo);
        this.form.location = cloneDeep(response.data.user.university.location);
        this.form.tel = cloneDeep(response.data.user.university.tel);
        this.form.postal_code = cloneDeep(response.data.user.university.postal_code);
      }
      if (this.form.postal_code === '') {
        this.isInitSeting = false;
      }
      this.$store.dispatch('page/offLoading');
    },
    // 更新
    async save() {
      this.errors = {};
      this.$store.dispatch('page/onWaiting');
      const params = {
        newPassword: this.newPassword,
        newPasswordConf: this.newPasswordConf,
        changePassword: this.changePassword,
        busyo: this.form.busyo,
        location: this.form.location,
        tel: this.form.tel,
        postal_code: this.form.postal_code,
      };
      const ressponse = await api.send('/api/uni/setting/save', params)
        .catch((error) => {
          this.errors = error.response.data.message;
          return false;
        });
      if (ressponse !== false) {
        await this.alert('保存が完了しました。', false);
        this.init();
        await this.fetch();
      }
      this.$store.dispatch('page/offWaiting');
    },
    passCheck() {
      this.errors.newPasswordConf = [];
      this.$store.commit('cmsSetting/deleteErrMsg', 'newPasswordConf');
      if (this.newPassword !== this.newPasswordConf) {
        this.errors.newPasswordConf = ['パスワードの確認とパスワードが一致するよう入力してください。'];
      }
    },
  },
  // ロード画面
  created() {
    this.fetch();
  },
  watch: {
    'form.postal_code': {
      handler(newPostalCode) {
        if (this.isInitSeting) {
          this.isInitSeting = false;
          return;
        }
        if (newPostalCode.length < 7) {
          return;
        }
        let address = '';
        // eslint-disable-next-line
        new YubinBangoCore(newPostalCode, (addr) => {
          address = addr.region + addr.locality + addr.street;
          if (address === '') {
            return;
          }
          this.form.location = address;
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.title{
  color: #333333;
  font-weight: 300;
  font-size: 24px;
  margin: 0 100px;
  margin-top: 52px;
  border-bottom: solid 2px #A0A9D0;
  padding-left: 10px;
  padding-bottom: 7px;
}
.dataText{
  word-wrap: break-word;
}
.inputBox{
  /* text-align: center; */
  margin-top: 53px;
}
.passBox,
.input-wrap{
  width: 1000px;
  margin:auto;
  border-bottom: solid 1px #CCCCCC;
}

.nowMail .labelWrap label {
  width: 180px;
}
.nowMail .labelWrap {
  max-width: 300px;
  font-weight: bold;
}
.nowMail{
  margin:15px auto;
  margin-top: 30px;
}
.nowMail{
  text-align: left;
}
.nowMail .labelWrap{
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.pl-30{
  padding-left: 30px;
}
.check-wrap{
  text-align: left;
  padding-left: 33.5%;
}
.message{
  font-size: 13px;
  text-align: left;
  padding-left: 33.5%;
  margin-bottom: -7px;
}
.message .gray,
.nowMail .gray{
  color: #BBBBBB;
}
.nowMail input[type="text"]{
  width: 400px;
  height: 50px;
}
input[disabled]{
  border:solid 1px #F3F3F3 !important;
  background-color: #F3F3F3;
}
input[readonly]:not([disabled]){
  background-color: #FFFFFF;
}
input[readonly]:focus{
  outline: none;
  box-shadow:none;
}
button.btn{
  font-size: 18px;
  font-weight: 600;
  margin: 15px;
}
button.save{
  background-color: #122889!important;
  color: #FFFFFF!important;
}
.btnWrap{
  text-align: center;
  margin-top: 45px;
}
</style>
